import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ElectronService } from 'ngx-electron';
import { ModalModule } from 'modal';
import { ModalBoxButton, ModalBoxClose, ModalBoxEvent, ModalBoxModule } from 'modal-box';
import { DialogBoxConfig, DialogBoxModule } from 'dialog-box';
import { VersionBoxConfig, VersionBoxModule } from 'version-box';
import { Device, HybridsSocketModule } from 'hybrids-socket';
import { KeyboardConfig, KeyboardModule } from 'keyboard';
import { SqliteModule } from 'sqlite';
import { SubscriptionModule } from 'subscription';
import { WirelessModule } from 'wireless';
import { HybridsRepoModule, RepoConfig, RepositoryType } from 'hybrids-repo';
import { ReplicaModule,
         ReplicaConfig,
         FloatNumberPipe,
         FormatValuePipe,
         IpcService,
         ParameterToValuePipe } from 'replica';
import { environment } from 'src/environments/environment';
import { HttpManagerConfig, HttpManagerModule } from 'http-manager';
import { HybridsMenuModule } from 'hybrids-menu';

//inicializamos los prototype de objetos globales
import { global } from 'common';

global.init();


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ModalBoxModule.forRoot(),
    DialogBoxModule.forRoot(new DialogBoxConfig({
      accept: new ModalBoxButton({
        title: 'aceptar',
        event: ModalBoxEvent.ACCEPT
      }),
      cancel: new ModalBoxButton({
        title: 'cancelar',
        event: ModalBoxEvent.CANCEL
      })
    })),
    VersionBoxModule.forRoot(new VersionBoxConfig({
      close: new ModalBoxClose({
        event: ModalBoxEvent.CANCEL,
        icon: './replica/assets/images/home/close_enabled.svg'
      }),
      image: './replica/assets/images/home/isotype.svg'
    })),
    SqliteModule.forRoot({file: '../assets/database/replica.json'}),

    HybridsRepoModule.forRoot(new RepoConfig({ repository: RepositoryType.SQLITE })),
    HybridsSocketModule.forRoot(),
    HybridsMenuModule.forRoot(),
    WirelessModule.forRoot(),
    SubscriptionModule.forRoot(),
    KeyboardModule.forRoot(new KeyboardConfig({ active: true })),
    ReplicaModule.forRoot(new ReplicaConfig({
      endPointSocket: environment.endPointSocket,
      device: Device.NONE,
      isVR: false
    })),
    HttpManagerModule.forRoot(new HttpManagerConfig({
      defaultLanguage: environment.defaultLanguage,
      product: environment.product
    }))
  ],
  providers: [
    IpcService,
    DatePipe,
    DecimalPipe,
    ParameterToValuePipe,
    FloatNumberPipe,
    FormatValuePipe,
    ElectronService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
     { prefix: './replica/assets/i18n/', suffix: '.json' }, //replica assets
     { prefix: './keyboard/assets/i18n/', suffix: '.json' }, //keyboard assets
     { prefix: './wireless/assets/i18n/', suffix: '.json' }, //wireless assets
     { prefix: './modal-box/assets/i18n/', suffix: '.json' }, //modal-box assets
     { prefix: './version-box/assets/i18n/', suffix: '.json' } //version-box assets
   ])
 }
